import React from 'react';
import { DrawerHeader, Main } from '../../styled';
import DashboardContainer from './DashboardContainer';
import ContentArea from '../../components/ContentArea';


function DashboardDemo({ drawerOpen, drawerWidth }) {

  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<DashboardContainer />} />
    </Main>
  );
};

export default DashboardDemo;