import React from 'react';
import { Grid, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useDocManagerState } from '../../components/useDocManagerState';

function EditDocControls({ handleUpdateContent }) {

  const { setEditDoc } = useDocManagerState();

  return (
    <Grid sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <IconButton
        sx={{
          background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)`,
          marginRight: '1rem'
        }}
        onClick={() => handleUpdateContent()}
      >
        <SaveIcon sx={{color: 'white'}} />
      </IconButton>
      <IconButton 
        sx={{
          background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)` 
        }}
        onClick={() => setEditDoc(false)}
      >
        <CloseIcon sx={{color: 'white'}} />
      </IconButton>
    </Grid>
  )
}

export default EditDocControls;