import React, { useEffect, useState } from 'react'
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@mui/material';
import SubscriptionCard from './SubscriptionCard';
import useCurrentUserInfo from '../../utils/useCurrentUserInfo';
import { useDispatch, useSelector } from 'react-redux';
import { cancelUserSubscription, updateUserSubscriptionData } from '../../actions/userActions';
import subscriptionPlans from '../../consts/subscriptionPlans';

function SubscriptionSelection() {

  const dispatch = useDispatch();
  const userInfo = useCurrentUserInfo();
  const currentSubscription = useSelector((state) => state?.userData?.loginInfo?.stripeProductId);

  const [checkInterval, setCheckInterval] = useState(null);
  const [open, setOpen] = useState(false);

  // Function to check subscription status
  const checkSubscriptionStatus = async () => {

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}api/user/subscription-status/${userInfo?.user_id}`, 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      if (!response?.status === 200) {
        throw new Error('Failed to fetch subscription status');
      }

      const { data } = await response.json();

      if (data.subscriptionProductId !== userInfo.subscriptionProductId) {
        dispatch(
          updateUserSubscriptionData(data, dispatch)
        );
        stopSubscriptionCheck();
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
    }
  };

  const currentlySelectedPlan = (plan) => {
    if(currentSubscription === plan.subscriptionProductId){
      return true;
    };

    if(!currentSubscription && !plan?.stripeLink){
      return true;
    };

    return false;
  };

  const handleCancelSubscription = () => {
    dispatch( 
      cancelUserSubscription({
        subscriptionId: userInfo?.stripeSubscriptionId,
        userId: userInfo?.user_id
      }, dispatch) 
    );
  };

  const startSubscriptionCheck = () => {
    const interval = setInterval(checkSubscriptionStatus, 2000);
    setCheckInterval(interval);
  };

  const stopSubscriptionCheck = () => {
    if (checkInterval) {
      clearInterval(checkInterval);
      setCheckInterval(null);
    };
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleCancelSubscription();
  };

  useEffect(() => {
    return () => {
      if (checkInterval) {
        clearInterval(checkInterval);
      }
    };
  }, [checkInterval, currentSubscription]);

  const handleSubscriptionClick = () => {
    startSubscriptionCheck();
  };

  const currentPlanObject = subscriptionPlans.filter(
    (plan) => currentSubscription === plan.subscriptionProductId
  )[0] ?? subscriptionPlans[0];

  const currentPlanTitle = currentPlanObject?.title;

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid sx={{marginBottom: '2rem'}}>
      <Typography
        variant="h2" 
        component="h1" 
        align="center" 
        gutterBottom
      >
        Choose Your Plan
      </Typography>
      <Grid sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <Typography variant='h5' sx={{ marginRight: '1rem'}}>
          Current Plan:
        </Typography>
        <Typography variant='h5' color={'primary'}>
          {currentPlanTitle ?? 'Basic'}
        </Typography>
      </Grid>
      </Grid>
      <Grid container spacing={4}>
        {subscriptionPlans.map((plan) => (
          <Grid item xs={12} md={4} key={plan.title}>
            <SubscriptionCard
              {...plan}
              isCurrentPlan={currentlySelectedPlan(plan)}
              onSubscriptionClick={handleSubscriptionClick}
              cancelSubscription={handleClickOpen}
            />
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Cancel Subscription
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to cancle your subscription? 
            You'll need to <b>buy another one at full price</b> in order to resume usage, 
            although you will keep all of the credits you've already purchased.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            variant='contained'
            onClick={handleClose} 
            color="primary"
            sx={{ color: 'white' }}
          >
            Keep Subscription
          </Button>
          <Button onClick={handleConfirm} color="error" variant="contained" autoFocus>
            Confirm Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default SubscriptionSelection