import Joyride from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';
import { theme } from '@gilbarbara/components';
import { useAppContext } from './context';
import { useMediaQuery } from '@mui/material';
import tourSteps from './steps';

export default function MultiRouteWrapper() {
  // Use the context and navigation hooks
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const {
    setState,
    state: { run, stepIndex, steps },
  } = useAppContext();

  const navigate = useNavigate();

  // Set initial steps for Joyride on component mount
  useMount(() => {
    setState({ steps: tourSteps(isMobile) });
  });

  // Handle the callback from Joyride
  const handleCallback = (data) => {
    const {
      action,
      index,
      step: {
        data: { next, previous },
      },
      type,
    } = data;

    const isPreviousAction = action === 'prev';

    if (type === 'tour:end' || action === 'skip') {
      console.log('Tour ended or skipped');
      setState({ run: false, stepIndex: 0, tourActive: false });
      return;
    };

    if (type === 'step:after') {
      if (isPreviousAction && previous) {
        navigate(previous);
        setState({ run: true, stepIndex: index - 1, tourActive: true })
        return;
      }
  
      if (index < steps.length - 1) {
        if (next) {
          navigate(next);
          setState({ run: true, stepIndex: index + 1, tourActive: true });
          return;
        };
      } else {

        setState({ run: false, stepIndex: 0, tourActive: false });
        return;
      }
    }
  };

  return (
    // Render the Joyride component
    <Joyride
      callback={handleCallback}
      continuous
      run={run}
      stepIndex={stepIndex}
      steps={steps}
      disableOverlayClose
      disableScrolling
      showSkipButton
      hideCloseButton
      // Uncomment and adapt styles as needed
      styles={{
        options: {
          arrowColor: theme.black,
          backgroundColor: theme.black,
          primaryColor: theme.colors.blue,
          textColor: theme.white,
          zIndex: 100000
        },
      }}
    />
  );
}