import React, { useMemo, useState } from 'react'
import { useAppContext } from '../../unregisteredViews/LiveDemo/context';
import { 
  Dialog,
  DialogTitle, 
  DialogActions, 
  Button, 
  Typography, 
  Grid 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

function DemoDialog({ onClose }) {

  const {
    setState,
    state,
  } = useAppContext();
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const [showDialog, setShowDialog] = useState(false);

  const showDialogConditional = (demoState) => {
    if(!demoState?.tourActive || !demoState?.run){
      setShowDialog(true);
      return;
    };

    setShowDialog(false);
  };

  const handleRestartTour = () => {
    setState({ run: true, tourActive: true, stepIndex: 0 })
    navigate('/demo/ai-chat');
  };

  const isInstagramBrowser = () => {
    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
    return (userAgent.indexOf('Instagram') > -1);
  };

  const handleAuthRedirect = (authFunction) => {
    if (isInstagramBrowser()) {
      // Open in new window
      const newWindow = window.open('', '_blank');
      authFunction({
        redirectUri: `${window.location.origin}/dashboard`
      })
      .then(() => {
        if (newWindow) {
          newWindow.location.href = window.location.href;
        }
      })
      .catch(() => {
        if (newWindow) {
          newWindow.close();
        }
      });
    } else {
      authFunction({
        appState: { returnTo: '/dashboard' }
      });
    };
  };

  const handleLogin = () => {
    handleAuthRedirect(loginWithRedirect);
  };

  const handleRegister = () => {
    handleAuthRedirect(() => loginWithRedirect({ 
      authorizationParams: { screen_hint: "signup" } 
    }));
  };

  useMemo(() => {
    showDialogConditional(state)
  }, [state]);

  return (
    <Dialog
      open={showDialog}
      onClose={onClose}
      aria-labelledby="options-dialog-title"
      fullWidth
      maxWidth="md"
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <DialogTitle 
        id="options-dialog-title"
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
        }}
      >
        {
          isAuthenticated ? (
            <Typography sx={{fontSize: '1.5rem', textAlign: 'center' }}>
              Start Tour or Return Home!
            </Typography>
          ) : (
            <Typography sx={{fontSize: '1.5rem', textAlign: 'center' }}>
              Register, Sign In, or Take Interactive Tour!
            </Typography>
          )
        }
      </DialogTitle>

      <DialogActions 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          width: '100%',
          height: '15rem',
          justifyContent: 'space-between'
        }}
      >
        <Grid 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'space-between',
            height: '5rem'
          }}
        >
          <Button 
            variant='contained'
            onClick={handleRestartTour} 
            color="primary" 
            sx={{ width: '15rem', marginBottom: '3rem'}}
          >
            <Typography variant='h6' sx={{ color: 'white' }}>
              Start Tour
            </Typography>
          </Button>
          {
            isAuthenticated ? (
              <Button 
                onClick={() => navigate('/dashboard')} 
                color="primary" 
                variant='outlined'
                sx={{ width: '15rem', marginBottom: '1rem' }}
              >
                <Typography variant='h6'>
                  Return Home
                </Typography>
              </Button>
            ) : (
              <>
                <Button 
                  onClick={handleLogin} 
                  color="primary" 
                  variant='outlined'
                  sx={{ width: '15rem', marginBottom: '1rem' }}
                >
                  <Typography variant='h6'>
                    Sign In
                  </Typography>
                </Button>
                <Button 
                  onClick={handleRegister} 
                  color="primary" 
                  variant='contained'
                  sx={{ width: '15rem', color: 'white' }}
                >
                  <Typography variant='h6'>
                    Register Free
                  </Typography>
                </Button>
              </>
            )
          }
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default DemoDialog;