import React, { useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import Showdown from 'showdown';


function handleAIResponse(aiResponse) {
  if (!aiResponse) return '';
  const converter = new Showdown.Converter({
    tables: true, // Enable table support in Showdown
  });

  // First, handle ASCII tables
  const processedResponse = aiResponse.replace(
    /^\+[-+]+\+\n(?:\|[^|]+\|(?:\n\|[^|]+\|)*\n\+[-+]+\+)/gm,
    (match) => {
      // Convert ASCII table to HTML table
      const rows = match.split('\n').filter(row => row.startsWith('|'));
      const htmlRows = rows.map(row => {
        const cells = row.split('|').filter(cell => cell.trim());
        const isHeader = row === rows[0];
        const cellType = isHeader ? 'th' : 'td';
        return `<tr>${cells.map(cell => `<${cellType}>${cell.trim()}</${cellType}>`).join('')}</tr>`;
      });
      return `<table>${htmlRows.join('')}</table>`;
    }
  );

  // Handle code blocks
  const codeProcessed = processedResponse.replace(
    /```(\w+)?\n([\s\S]*?)```/g,
    (match, language, code) => {
      return `<pre><code class="language-${language || 'plaintext'}">${
        code.trim()
      }</code></pre>`;
    }
  );

  // Convert the rest of the markdown (including markdown tables)
  let htmlContent = converter.makeHtml(codeProcessed);

  // Clean up any remaining markdown-style tables that weren't caught
  htmlContent = htmlContent.replace(
    /\|([^|]+)\|/g,
    (match, content) => {
      const cells = content.split('|').map(cell => cell.trim());
      return `<tr>${cells.map(cell => `<td>${cell}</td>`).join('')}</tr>`;
    }
  );

  // Ensure all tables have proper structure
  htmlContent = htmlContent.replace(
    /<table>((?:<tr>.*?<\/tr>)+)<\/table>/g,
    (match, content) => {
      const rows = content.match(/<tr>.*?<\/tr>/g);
      if (!rows) return match;
      
      const firstRow = rows[0].replace(/<td>/g, '<th>').replace(/<\/td>/g, '</th>');
      const otherRows = rows.slice(1);
      
      return `<table><thead>${firstRow}</thead><tbody>${otherRows.join('')}</tbody></table>`;
    }
  );

  return htmlContent;
}

function TinyMceEditor({ content, setContent }) {
  const editorRef = useRef(null);

  const handleEditorChange = (updatedContent, editor) => {
    setContent(updatedContent);
  };
  
  const processedContent = handleAIResponse(content);

  return (
    <div>
      <style>
        {`
          .tox .tox-promotion,
          .tox .tox-promotion-link {
            display: none !important;
          }
        `}
      </style>
      <Editor
        tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/js/tinymce/tinymce.min.js`}
        licenseKey='gpl'
        onInit={(_evt, editor) => editorRef.current = editor}
        onEditorChange={handleEditorChange}
        value={processedContent}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          setup: (editor) => {
            editor.on('Change', () => {
              const newContent = editor.getContent();
              setContent(newContent);
            });
          }
        }}
        style={{ '.tox-promotion': { display: 'none!important'}}}
      />
    </div>
  );
}

export default TinyMceEditor;