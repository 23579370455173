import axios from "axios";
import { 
  CLEAR_TEMP_INVITE_DATA,
  COLLABORATOR_USER_INFO_FAIL, 
  COLLABORATOR_USER_INFO_REQUEST, 
  COLLABORATOR_USER_INFO_SUCCESS, 
  DELETE_CHAT_FAIL, 
  DELETE_CHAT_REQUEST, 
  DELETE_CHAT_SUCCESS, 
  INVITE_DELIVER_FAIL, 
  REMOVE_COLLABORATOR_FAIL, 
  REMOVE_COLLABORATOR_REQUEST, 
  REMOVE_COLLABORATOR_SUCCESS, 
  REMOVE_FOLDER_COLLABORATOR_FAIL, 
  REMOVE_FOLDER_COLLABORATOR_REQUEST, 
  REMOVE_FOLDER_COLLABORATOR_SUCCESS, 
  REMOVE_FOLDER_INVITE_FAIL, 
  REMOVE_FOLDER_INVITE_REQUEST, 
  REMOVE_FOLDER_INVITE_SUCCESS, 
  REMOVE_INVITE_FAIL, 
  REMOVE_INVITE_REQUEST, 
  REMOVE_INVITE_SUCCESS, 
  SEND_CHAT_FAIL, 
  SEND_CHAT_REQUEST, 
  SEND_CHAT_SUCCESS, 
  SEND_FOLDER_COLLAB_INVITE_ERROR, 
  SEND_FOLDER_COLLAB_INVITE_REQUEST, 
  SEND_FOLDER_COLLAB_INVITE_SUCCESS, 
  STORE_TEMP_INVITE_DATA, 
  UPDATE_COLLABORATORS_FAIL, 
  UPDATE_COLLABORATORS_REQUEST, 
  UPDATE_COLLABORATORS_SUCCESS, 
  UPDATE_FOLDER_COLLABORATORS_FAIL, 
  UPDATE_FOLDER_COLLABORATORS_REQUEST,
  UPDATE_FOLDER_COLLABORATORS_SUCCESS
} from "../consts/collaboratorsConstants";
import { 
  SEND_COLLAB_INVITE_ERROR, 
  SEND_COLLAB_INVITE_REQUEST, 
  SEND_COLLAB_INVITE_SUCCESS 
} from "../consts/gptChatConstants";

export const sendCollabInvite = ({messageId, recipientEmail, permission}) => async(dispatch) => {

  dispatch({ type: SEND_COLLAB_INVITE_REQUEST });

  try {

    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}api/messages/sendInvite`, 
      {
        messageId: messageId,
        recipientEmail: recipientEmail,
        permission: permission
      }
    );

    dispatch({ type: SEND_COLLAB_INVITE_SUCCESS, payload: response?.data?.updatedMessage });
    dispatch({ type: CLEAR_TEMP_INVITE_DATA });

  } catch (error) {
    dispatch({ type: SEND_COLLAB_INVITE_ERROR, payload: error.message});
    dispatch({ type: INVITE_DELIVER_FAIL });
  };
};

export const retrieveCollaboratorUsersInfo = (userIds) => async(dispatch) => {

  dispatch({ type: COLLABORATOR_USER_INFO_REQUEST });

  try{

    const response = await axios.post(`${process.env.REACT_APP_API_PATH}api/user/collaboratorsInfo`, {
      collaboratorIds: userIds
    });

    dispatch({ type: COLLABORATOR_USER_INFO_SUCCESS, payload: response?.data });

  } catch( error ){

    dispatch({ type: COLLABORATOR_USER_INFO_FAIL, payload: error.message });
  };
};

export const removeCollaborators = ({ deletedCollabs, messageId }) => async(dispatch) => {

  dispatch({ type: REMOVE_COLLABORATOR_REQUEST });

  try{
    const url =  `${process.env.REACT_APP_API_PATH}api/messages/${messageId}`;
    const response = await axios.post(url, { deletedCollabs: deletedCollabs });


    if(response.status === 200){
      
      dispatch({ 
        type: REMOVE_COLLABORATOR_SUCCESS, 
        payload: { 
          messageId: messageId, 
          sharedWith: response?.data?.updatedMessage?.sharedWith 
        }
      });
    };
  } catch(error){

    dispatch({ type: REMOVE_COLLABORATOR_FAIL, payload: error.message});
  };
};

export const updateCollaboratorsPermissions = ({ updatedCollabs, messageId }) => async(dispatch) => {

  dispatch({ type: UPDATE_COLLABORATORS_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}api/messages/${messageId}`, 
      { updatedCollabs: updatedCollabs }
    );

    if(response.status === 200){
      dispatch({ 
        type: UPDATE_COLLABORATORS_SUCCESS, 
        payload: { messageId: messageId, sharedWith: response?.data?.updatedMessage?.sharedWith }
      });
    };

  } catch( error ){
    dispatch({ type: UPDATE_COLLABORATORS_FAIL, payload: error?.message });
  };
};

export const removeInvite = ({ messageId, inviteId }) => async(dispatch) => {
  dispatch({ type: REMOVE_INVITE_REQUEST });

  try {
    const response = await axios.put(`${process.env.REACT_APP_API_PATH}api/messages/${messageId}/removeInvite/${inviteId}`);

    if(response?.status === 200){
      dispatch({ type: REMOVE_INVITE_SUCCESS, payload: response?.data?.sharedWith});
    };
  } catch (error){
    dispatch({ type: REMOVE_INVITE_FAIL, payload: error?.message });
  };
};

export const sendNewChat = ({
  userId, 
  documentId, 
  chatMessageContent
}) => async(dispatch) => {

  dispatch({ type: SEND_CHAT_REQUEST });

  try{
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}api/messages/newChat`,
      {
        userId: userId,
        documentId: documentId,
        chatMessageContent: chatMessageContent
      } 
    );

    if(response.status === 200){
      dispatch({ type: SEND_CHAT_SUCCESS, payload: response?.data });
    };

  } catch (error) {

    dispatch({ type: SEND_CHAT_FAIL, payload: error.message });
  };
};

export const deleteCollabChat = (chatId, documentId) => async(dispatch) => {

  dispatch({ type: DELETE_CHAT_REQUEST });

  try{
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}api/messages/deleteChat`,
      { chatId: chatId, documentId: documentId } 
    );

    if(response.status === 200){
      dispatch({ 
        type: DELETE_CHAT_SUCCESS, 
        payload: { documentId: documentId, chatId: chatId } 
      });
    };

  } catch (error) {

    dispatch({ type: DELETE_CHAT_FAIL, payload: error.message });
  };
};

export const editChatMessage = ({
  userId, 
  documentId, 
  chatMessageContent,
  chatMessageId
}) => async(dispatch) => {

  dispatch({ type: SEND_CHAT_REQUEST });

  try{
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}api/messages/editChat`,
      {
        userId: userId,
        documentId: documentId,
        chatMessageContent: chatMessageContent,
        chatMessageId: chatMessageId
      } 
    );

    if(response.status === 200){
      dispatch({ type: SEND_CHAT_SUCCESS, payload: response?.data });
    };

  } catch (error) {

    dispatch({ type: SEND_CHAT_FAIL, payload: error.message });
  };
};

export const deleteChatMessage = ({
  userId, 
  documentId, 
  chatMessageId
}) => async(dispatch) => {

  dispatch({ type: SEND_CHAT_REQUEST });

  try{
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}api/messages/deleteChat`,
      {
        userId: userId,
        documentId: documentId,
        chatMessageId: chatMessageId
      } 
    );

    if(response.status === 200){
      dispatch({ type: SEND_CHAT_SUCCESS, payload: response?.data });
    };

  } catch (error) {

    dispatch({ type: SEND_CHAT_FAIL, payload: error.message });
  };
};

// Folder Actions
export const sendFolderCollabInvite = ({folderId, recipientEmail, permission}) => async(dispatch) => {

  dispatch({ type: SEND_FOLDER_COLLAB_INVITE_REQUEST });
  dispatch({ 
    type: STORE_TEMP_INVITE_DATA, 
    payload: { 
      folderId: folderId,
      recipientEmail: recipientEmail,
      permission: permission
    }
  });

  try {

    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}api/folders/sendInvite`, 
      {
        folderId: folderId,
        recipientEmail: recipientEmail,
        permission: permission
      }
    );

    dispatch({ type: SEND_FOLDER_COLLAB_INVITE_SUCCESS, payload: response?.data?.updatedFolder });
    dispatch({ type: CLEAR_TEMP_INVITE_DATA });

  } catch (error) {
    dispatch({ type: SEND_FOLDER_COLLAB_INVITE_ERROR, payload: error.message});
    dispatch({ type: INVITE_DELIVER_FAIL });
  };
};

export const removeFolderCollaborators = ({ deletedCollabs, folderId }) => async(dispatch) => {

  dispatch({ type: REMOVE_FOLDER_COLLABORATOR_REQUEST });

  try{
    const url =  `${process.env.REACT_APP_API_PATH}api/folders/${folderId}`;
    const response = await axios.post(url, { deletedCollabs: deletedCollabs });


    if(response.status === 200){
      dispatch({ 
        type: REMOVE_FOLDER_COLLABORATOR_SUCCESS, 
        payload: { 
          folderId: folderId, 
          sharedWith: response?.data?.updatedMessage?.sharedWith 
        }
      });
    };
  } catch(error){

    dispatch({ type: REMOVE_FOLDER_COLLABORATOR_FAIL, payload: error.message});
  };
};

export const updateFolderCollaboratorsPermissions = ({ updatedCollabs, folderId }) => async(dispatch) => {

  dispatch({ type: UPDATE_FOLDER_COLLABORATORS_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}api/folders/${folderId}`, 
      { updatedCollabs: updatedCollabs }
    );

    if(response.status === 200){
      dispatch({ 
        type: UPDATE_FOLDER_COLLABORATORS_SUCCESS, 
        payload: { folderId: folderId, sharedWith: response?.data?.updatedMessage?.sharedWith }
      });
    };

  } catch( error ){
    dispatch({ type: UPDATE_FOLDER_COLLABORATORS_FAIL, payload: error?.message });
  };
};

export const removeFolderInvite = ({ folderId, inviteId }) => async(dispatch) => {
  dispatch({ type: REMOVE_FOLDER_INVITE_REQUEST });

  try {
    const response = await axios.put(`${process.env.REACT_APP_API_PATH}api/folders/${folderId}/removeInvite/${inviteId}`);

    if(response?.status === 200){
      dispatch({ type: REMOVE_FOLDER_INVITE_SUCCESS, payload: response?.data?.sharedWith});
    };
  } catch (error){
    dispatch({ type: REMOVE_FOLDER_INVITE_FAIL, payload: error?.message });
  };
};