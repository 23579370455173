import { useMemo, useEffect, useState, useContext, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveCollaboratorUsersInfo } from '../../actions/collaboratorActions';
import { useAuth0 } from '@auth0/auth0-react';
import useCurrentUserInfo from '../../utils/useCurrentUserInfo';
import { augmentSharedWithCollaboratorInfo } from '../../containers/DocumentManager/helpers';

export const DocManagerContext = createContext();

export function DocManagerProvider({ children }) {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const userInfo = useCurrentUserInfo();
  const userId = userInfo?.user_id;
  
  const ownedMessageFolders = useSelector((state) => state.gptChat.ownedFolders);
  const sharedMessageFolders = useSelector((state) => state.gptChat.externalFolders);
  const allUsersCollaborators = useSelector((state) => state?.collaborators?.retrievedCollaborators);
  const ownedMessages = useSelector((state) => state.gptChat.ownedDbMessages);
  const externalMessages = useSelector((state) => state.gptChat.externalDbMessages);
  const trialUserSavedGPTMessages = useSelector((state) => state.gptChat.trialMessageSaves);

  const [collaboratorsOpen, setCollaboratorsOpen] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [folderCollaborators, setFolderCollaborators] = useState([]);
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [documentContent, setDocumentContent] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [documentFolderId, setDocumentFolderId] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState({ _id: 0, name: 'All' });
  const [docCollaboratorChats, setDocCollaboratorChats] = useState([]);
  const [filteredDocs, setFilteredDocs] = useState([]);
  const [savedFiles, setSavedFiles] = useState(null);
  const [documentChatHistory, setDocumentChatHistory] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [editDoc, setEditDoc] = useState(false);
  const [managerView, setManagerView] = useState('Folders');

  const checkOwnedAndExternalMessagesAndReturnArray = () => {
    if(ownedMessages && externalMessages){
      return [...ownedMessages, ...externalMessages];
    };

    if(!ownedMessages && externalMessages){
      return [...externalMessages];
    };

    if(ownedMessages && !externalMessages){
      return [...ownedMessages];
    };
  };

  const collaboratorsUserInfo = () => {
    const messageCollaboratorIds = selectedFileData?.sharedWith?.map((user) => user.user_id) ?? [];
    const folderCollaboratorIds = selectedFolder?.sharedWith?.map((user) => user.user_id) ?? [];

    const collaboratorsIds = [...messageCollaboratorIds, folderCollaboratorIds];

    const filterUnfetchedCollaborators = (ids, allUsers) => {
      return ids?.filter(id => !allUsers.some(user => user.user_id === id));
    };

    const missingCollaboratorIds = filterUnfetchedCollaborators(collaboratorsIds, allUsersCollaborators);

    if (missingCollaboratorIds.length) {
      dispatch(retrieveCollaboratorUsersInfo(missingCollaboratorIds, dispatch));
    };
  };

  const userIsOwner = () => {
    if(selectedFileData?.ownerId !== userId){
      return false;
    };

    return true;
  };

  const userCanEdit = () => {
    const userInMessageObject = selectedFileData?.sharedWith?.filter((user) => user?.user_id === userId);
    if(userInMessageObject){
      const userPermission = userInMessageObject[0]?.permission;

      if(userPermission === 'canEdit'){
        return true;
      };
    };

    return false;
  };

  
  // Update local file data for lifecycle protection
  useMemo(() => {
    if (selectedFileData) {
      setDocumentContent(selectedFileData?.content);
      setDocumentName(selectedFileData?.docName);
      setDocumentFolderId(selectedFileData?.folderId);
      setDocCollaboratorChats(selectedFileData?.collabChats);
      setDocumentChatHistory(selectedFileData?.documentChatHistory);
      setDocumentId(selectedFileData?._id)
    };
  }, [selectedFileData]);

  // Enrich file data with collab user data from database
  useMemo(() => {
    if (allUsersCollaborators) {
      const folderSharedWith = selectedFolder?.sharedWith || [];
      const fileSharedWith = selectedFileData?.sharedWith || [];
  
      // Create a Set of user_ids from fileSharedWith for quick lookup
      const fileSharedUserIds = new Set(fileSharedWith.map(user => user.user_id));
  
      // Filter the folderSharedWith to exclude any user_id already in fileSharedWith
      const exclusiveFolderSharedWith = folderSharedWith.filter(
        user => !fileSharedUserIds.has(user.user_id)
      );
  
      // Combine the unique folderSharedWith with fileSharedWith
      const combinedSharedWith = [...fileSharedWith, ...exclusiveFolderSharedWith];
  
      // Create a new fileData object to pass it along with the collaborators
      const combinedFileData = { ...selectedFileData, sharedWith: combinedSharedWith };
  
      // Augment the combined sharedWith array
      const updatedFileData = augmentSharedWithCollaboratorInfo(combinedFileData, allUsersCollaborators);
  
      setCollaborators(updatedFileData?.sharedWith);
    }
  }, [allUsersCollaborators, selectedFileData, selectedFolder]);

  // Same enrichment as above but specific to folderData
  useMemo(() => {
    if (allUsersCollaborators && selectedFolder?.sharedWith) {
      const updatedFolderData = augmentSharedWithCollaboratorInfo(selectedFolder, allUsersCollaborators);
      setFolderCollaborators(updatedFolderData?.sharedWith);
    };

  }, [allUsersCollaborators, selectedFolder]);

  // List of files update lifecycle protection
  useMemo(() => {
    setSavedFiles(
      checkOwnedAndExternalMessagesAndReturnArray()
    );

    // eslint-disable-next-line
  }, [ownedMessages, externalMessages])


  // Does this do the same thing as above enrichment?
  useEffect(() => {
    if (selectedFileData?.sharedWith?.length || selectedFolder?.sharedWith?.length) {
      collaboratorsUserInfo();
    }
  }, [selectedFileData?.sharedWith, selectedFolder?.sharedWith]);


  // Filter docs when new folder is selected
  useEffect(() => {

    if(
      selectedFolder && 
      selectedFolder?._id !== 0 && 
      isAuthenticated
    ){
      setFilteredDocs(
        savedFiles?.filter(
          (message) => message?.folderId === selectedFolder._id
        )
      );

      return;
    };

    if(isAuthenticated && (selectedFolder?._id === 0)){
      setFilteredDocs(savedFiles);
      return;
    };

    if(selectedFolder && !isAuthenticated){

      setFilteredDocs(
        trialUserSavedGPTMessages?.filter(
          (message) => message.folderData?._id === selectedFolder._id
        )
      );
      return;
    }  

    if(!selectedFolder && !isAuthenticated){
      setFilteredDocs(trialUserSavedGPTMessages);
      return;
    }
    //eslint-disable-next-line
  }, [selectedFolder, ownedMessages, externalMessages]);
  
  const docManagerStateValues = useMemo(() => ({
    ownedMessageFolders,
    sharedMessageFolders,
    docCollaboratorChats,
    collaboratorsOpen,
    collaborators,
    folderCollaborators,
    selectedFileData,
    documentContent,
    documentName,
    documentFolderId,
    selectedFolder,
    filteredDocs,
    savedFiles,
    documentChatHistory,
    documentId,
    managerView,
    editDoc,
    userCanEdit,
    setEditDoc,
    setManagerView,
    userIsOwner,
    setCollaboratorsOpen,
    setCollaborators,
    setFolderCollaborators,
    setSelectedFileData,
    setDocumentContent,
    setDocumentName,
    setDocumentFolderId,
    setSelectedFolder,
    setFilteredDocs,
    setSavedFiles,
    setDocumentChatHistory,
    setDocumentId
    // Add other state and setters here
  }), [
    ownedMessageFolders,
    sharedMessageFolders,
    docCollaboratorChats,
    collaboratorsOpen,
    collaborators,
    folderCollaborators,
    selectedFileData,
    documentContent,
    documentName,
    documentFolderId,
    selectedFolder,
    filteredDocs,
    savedFiles,
    documentChatHistory,
    documentId,
    managerView,
    editDoc,
  ]);

  return (
    <DocManagerContext.Provider value={docManagerStateValues}>
      {children}
    </DocManagerContext.Provider>
  );
};

export const useDocManagerState = () => {
  const context = useContext(DocManagerContext);
  if (!context) {
    throw new Error('useDocManagerState must be used within a DocManagerProvider');
  };

  return context;
};