import React, { useState } from 'react'
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid, 
  IconButton, 
  Tooltip, 
  Typography, 
  useMediaQuery 
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined';

function DefaultButtonGroup({
  folderName,
  userCanEditName,
  setEditName,
  userCanMoveDoc,
  setMoveBulkDocuments,
  handleDeleteFolder,
  userCanDeleteFolder
}) {

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleDeleteFolder();
  };

  return (
    <Grid
      sx={{
        display: 'flex', 
        flex: '1', 
        justifyContent: 'center', 
        alignItems: 'center'
      }}
    >
      <Grid 
        sx={{
          flex: isMobile && '9', 
          height: '100%', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: isMobile ? 'flex-start' : 'center'
        }}
      >
        <Typography variant='h5'>
          {folderName}
        </Typography>
      </Grid>
      <Grid 
        sx={{
          marginLeft: isMobile && '1rem', 
          display: userCanEditName() ? 'flex' : 'none'
        }}
      >
        <Tooltip title='Edit Name'>
          <IconButton
            size='small'
            sx={{
              marginLeft: isMobile ? '0' : '2rem', 
              border: '1px solid gray'
            }}
            onClick={() => setEditName(true)}
          >
            <EditIcon sx={{color: 'gray', fontSize: isMobile && '1rem'}} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid 
        sx={{
          display: userCanMoveDoc() ? 'flex' : 'none',
          justifyContent: 'center'
        }}
      >
        <Tooltip title='Edit Name'>
          <IconButton 
            size='small'
            sx={{
              marginLeft: isMobile ? '0' : '1rem', 
              border: '1px solid gray'
            }}
            onClick={() => setMoveBulkDocuments(true)}
          >
            <ChecklistIcon sx={{color: 'gray', fontSize: isMobile && '1rem'}} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid sx={{ marginLeft: '1rem', display: !userCanDeleteFolder() && 'none' }}>
        <Tooltip title='Delete Folder'>
          <IconButton 
            size='small' 
            sx={{ border: '1px solid red'}}
            onClick={handleClickOpen}
          >
            <DeleteForeverOutlined sx={{ color: 'red' }} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Delete Folder
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this folder and all of its contents? This is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="error" variant="contained" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default DefaultButtonGroup;