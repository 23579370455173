import React from 'react'
import { 
  FormControl,
  Grid, 
  IconButton, 
  InputLabel, 
  MenuItem, 
  Select, 
  Tooltip, 
  useMediaQuery,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

function MoveBulkDocsButtonGroup({
  userCanMoveDoc,
  selectedFolder,
  handleMoveBulkDocuments,
  ownedFolders,
  folderToMoveDocsTo,
  setFolderToMoveDocsTo,
  setMoveBulkDocuments
}) {

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleCancel = () => {
    setMoveBulkDocuments(false);
    setFolderToMoveDocsTo(null);
  };

  return (
    <Grid 
      sx={{
        display: 'flex', 
        flex: '1', 
        justifyContent: 'center', 
        alignItems: 'center'
      }}
    >
      <Grid 
        sx={{
          flex: isMobile && '9', 
          height: '100%', 
          display: 'flex', 
          alignItems: 'center', 
          width: '15rem',
          justifyContent: isMobile ? 'flex-start' : 'center'
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="folder-name">Move To</InputLabel>
          <Select
            labelId="folder-name"
            id="demo-simple-select"
            value={folderToMoveDocsTo || selectedFolder}
            label="Move Folder"
            onChange={(e) => setFolderToMoveDocsTo(e.target.value)}
          >
            {
              ownedFolders?.map((folder) => (
                <MenuItem value={folder}>{folder?.name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid 
        sx={{
          marginLeft: isMobile && '1rem', 
        }}
      >
      </Grid>
      <Grid 
        sx={{
          display: userCanMoveDoc() ? 'flex' : 'none',
          justifyContent: 'center'
        }}
      >
        <Tooltip title='Move To Folder'>
          <IconButton 
            size='small'
            sx={{
              marginLeft: isMobile ? '0' : '1rem', 
              border: '1px solid gray'
            }}
            onClick={handleMoveBulkDocuments}
          >
            <SaveIcon sx={{color: 'gray', fontSize: isMobile && '1rem'}} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid sx={{ marginLeft: '1rem' }}>
        <Tooltip title='Cancel'>
          <IconButton 
            size='small'
            sx={{
              border: '1px solid gray'
            }}
            onClick={handleCancel}
          >
            <CloseOutlinedIcon sx={{color: 'gray', fontSize: isMobile && '1rem'}} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default MoveBulkDocsButtonGroup;