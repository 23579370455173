export const CREATE_PAYMENT_METHOD_REQUEST = 'CREATE_PAYMENT_METHOD_REQUEST';
export const CREATE_PAYMENT_METHOD_SUCCESS = 'CREATE_PAYMENT_METHOD_SUCCESS';
export const CREATE_PAYMENT_METHOD_FAIL = 'CREATE_PAYMENT_METHOD_FAIL';

export const PAYMENT_SUBMISSION_REQUEST = 'PAYMENT_SUBMISSION_REQUEST';
export const PAYMENT_SUBMISSION_SUCCESS = 'PAYMENT_SUBMISSION_SUCCESS';
export const PAYMENT_SUBMISSION_FAIL = 'PAYMENT_SUBMISSION_FAIL';

export const CARD_DELETE_REQUEST = 'CARD_DELETE_REQUEST';
export const CARD_DELETE_SUCCESS = 'CARD_DELETE_SUCCESS';
export const CARD_DELETE_FAIL = 'CARD_DELETE_FAIL';

export const USER_INFO_SAVE_REQUEST = 'USER_INFO_SAVE_REQUEST';
export const USER_INFO_SAVE_SUCCESS = 'USER_INFO_SAVE_SUCCESS';
export const USER_INFO_SAVE_FAIL = 'USER_INFO_SAVE_FAIL';

export const USER_ARTWORK_DELETE_REQUEST = 'USER_ARTWORK_DELETE_REQUEST';
export const USER_ARTWORK_DELETE_SUCCESS = 'USER_ARTWORK_DELETE_SUCCESS';
export const USER_ARTWORK_DELETE_FAIL = 'USER_ARTWORK_DELETE_FAIL';

export const SUBTRACT_CREDITS_REQUEST = 'SUBTRACT_CREDITS_REQUEST';
export const SUBTRACT_CREDITS_SUCCESS = 'SUBTRACT_CREDITS_SUCCESS';
export const SUBTRACT_CREDITS_FAIL    = 'SUBTRACT_CREDITS_FAIL'   ;

export const EDIT_USERNAME_EMAIL_REQUEST = 'EDIT_USERNAME_EMAIL_REQUEST';
export const EDIT_USERNAME_EMAIL_SUCCESS = 'EDIT_USERNAME_EMAIL_SUCCESS';
export const EDIT_USERNAME_EMAIL_FAIL = 'EDIT_USERNAME_EMAIL_FAIL';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const USER_SUB_DATA_UPDATE_REQUEST = 'USER_SUB_DATA_UPDATE_REQUEST';
export const USER_SUB_DATA_UPDATE_SUCCESS = 'USER_SUB_DATA_UPDATE_SUCCESS';
export const USER_SUB_DATA_UPDATE_FAIL = 'USER_SUB_DATA_UPDATE_FAIL';

export const USER_SUB_CANCEL_REQUEST = 'USER_SUB_CANCEL_REQUEST';
export const USER_SUB_CANCEL_SUCCESS = 'USER_SUB_CANCEL_SUCCESS';
export const USER_SUB_CANCEL_FAIL = 'USER_SUB_CANCEL_FAIL';

