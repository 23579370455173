import React from 'react'
import { useDocManagerState } from '../../components/useDocManagerState';
import useCurrentUserInfo from '../../utils/useCurrentUserInfo';
import { useDispatch } from 'react-redux';
import { MESSAGES_COLLECTION_SAVE_SUCCESS, UPDATE_TRIAL_GPT_MESSAGE_SUCCESS } from '../../consts/gptChatConstants';
import { deleteGPTFolder } from '../../actions/documentActions';
import { deleteGPTMessage, updateGptMessage } from '../../actions/gptChatActions';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

function DocManagerHandlers() {
  const dispatch = useDispatch();
  const userInfo = useCurrentUserInfo();
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const userId = userInfo?.user_id;
  const {
    setCollaborators,
    selectedFolder,
    selectedFileData,
    setManagerView,
    setEditDoc,
    documentContent,
    documentFolderId,
    documentName,
    documentId,
    documentChatHistory,
    setSelectedFolder
  } = useDocManagerState();

  const handleSaveCollaborators = (updatedCollaborators) => {
    setCollaborators(updatedCollaborators);
  };

  const handleContinueConversation = () => {
    dispatch({ 
      type: MESSAGES_COLLECTION_SAVE_SUCCESS, 
      payload: selectedFileData?.savedMessageHistory
    });

    if(isAuthenticated){
      navigate('/ai-chat');
    } else {
      navigate('/');
    };
  };

  const handleDeleteFolder = () => {

    dispatch(
      deleteGPTFolder(userId, selectedFolder?._id, dispatch)
    );

    setSelectedFolder({ _id: 0, name: 'All' });
  };

  const handleDeleteFile = () => {
    dispatch(
      deleteGPTMessage(userId, selectedFileData._id, dispatch)
    );

    navigate('/folders');
    setManagerView('Files');
  };

  const handleUpdateContent = async() => {

    if(isAuthenticated){
    
      dispatch(
        updateGptMessage(
          {
            content: documentContent, 
            folderId: documentFolderId, 
            docName: documentName, 
            messageId: selectedFileData?._id
          }
        )
      );

      setEditDoc(false);
      
    } else {
      dispatch({
        type: UPDATE_TRIAL_GPT_MESSAGE_SUCCESS,
        payload: {
          content: documentContent,
          folderId: documentFolderId,
          docName: documentName,
          savedMessageHistory: documentChatHistory,
          _id: documentId
        }
      });

      setEditDoc(false)
    };
  };

  return {
    handleSaveCollaborators,
    handleContinueConversation,
    handleDeleteFolder,
    handleDeleteFile,
    handleUpdateContent
  }
};

export default DocManagerHandlers;