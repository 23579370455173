import React from 'react';
import ContentArea from '../../components/ContentArea';
import { DrawerHeader, Main } from '../../styled';
import DemoChat from './DemoChat';


function AIChatDemo({ drawerOpen, drawerWidth }) {
  
  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<DemoChat />} />
    </Main>
  );
}

export default AIChatDemo;