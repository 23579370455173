import React, { useEffect } from 'react'
import ContentArea from '../../components/ContentArea';
import { DrawerHeader, Main } from '../../styled';
import { 
  ownedMessageFolders, 
  sharedMessageFolders,
  ownedMessages
} from './constants';
import DocDetailView from '../../containers/DocumentManager/DocDetailView';
import { useParams } from 'react-router-dom';
import { useDocManagerState } from '../../components/useDocManagerState';
import { useAppContext } from '../LiveDemo/context';

function DocumentDemo({ drawerOpen, drawerWidth }) {

  const params = useParams();

  const {
    selectedFolder,
    setSelectedFolder,
    setSelectedFileData
  } = useDocManagerState();

  const { state: { stepIndex } } = useAppContext();

  useEffect(() => {
  
    if (stepIndex === 5) {
      const _id = parseInt(params.messageId);
      const updatedFileData = [
        ...(ownedMessages ?? [])      
      ].find((msg) => msg._id === _id);
  
      setSelectedFileData(updatedFileData || null);

      const updatedFolder = ownedMessageFolders?.find(
        (folder) => folder?._id === updatedFileData?.folderId
      );

      if(updatedFolder){
        setSelectedFolder(updatedFolder);
      };

    } else {
      const updatedFolder = ownedMessageFolders?.find(
        (folder) => selectedFolder?._id === folder?._id
      );

      if(updatedFolder){
        setSelectedFolder(updatedFolder);
      };

    };
    //eslint-disable-next-line
  }, [ stepIndex ]);

  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<DocDetailView />} />
    </Main>
  );
}

export default DocumentDemo;