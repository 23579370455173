import React from 'react'
import ContentArea from '../../components/ContentArea';
import { DrawerHeader, Main } from '../../styled';
import SubscriptionSelection from '../../containers/SubscriptionSelection';

function Subscriptions({ drawerOpen, drawerWidth }) {
  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<SubscriptionSelection />} />
    </Main>
  )
}

export default Subscriptions