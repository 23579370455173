import React from 'react'
import { 
  Button, 
  Card, 
  CardActions, 
  CardContent, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Typography 
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';
import useCurrentUserInfo from '../../utils/useCurrentUserInfo';

function SubscriptionCard({
  title, 
  price, 
  features, 
  stripeLink, 
  isCurrentPlan,
  onSubscriptionClick,
  cancelSubscription,
  subscriptionPriceId,
}) {

  const userInfo = useCurrentUserInfo();

  const handleSubscriptionClick = async (e) => {
    e.preventDefault(); // Prevent default navigation
    
    try {
      // If user has existing subscription, call your backend to handle the update
      if (userInfo?.stripeCustomerId) {
        const response = await fetch(
          `${process.env.REACT_APP_API_PATH}api/stripe/create-checkout-session`, 
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userId: userInfo.user_id,
              priceId: subscriptionPriceId, // The price ID for this plan
              customerId: userInfo.stripeCustomerId,
            }),
          }
        );
  
        const { url } = await response.json();
        window.location.href = url; // Redirect to Stripe Checkout
      } else {
        // For new customers, use the payment link
        window.location.href = `${stripeLink}?prefilled_email=${encodeURIComponent(userInfo?.email)}&client_reference_id=${userInfo?.user_id}`;
      };

      onSubscriptionClick();
    } catch (error) {
      console.error('Error handling subscription:', error);
      // Handle error (show error message to user)
    }
  };

  return (
    <Card
      elevation={1} 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)',
        }
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h4" component="div" gutterBottom>
          {price === 0 ? 'Free' : `$${price}`}
          {price > 0 && <Typography variant="subtitle1" component="span">/month</Typography>}
        </Typography>
        {features && (
          <List>
            {features.map((feature, index) => (
              <ListItem key={index} sx={{ padding: 0 }}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CheckIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={feature} />
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
      <CardActions sx={{ padding: 2, mt: 'auto' }}>
        {
          stripeLink ? (
            <Button
              variant="contained"
              fullWidth
              disabled={isCurrentPlan}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: 'white' }}
              onClick={handleSubscriptionClick}
            >
              {isCurrentPlan ? 'Current Plan' : 'Subscribe'}
            </Button>
          ) : (
            <Button
              variant="contained"
              fullWidth
              disabled={isCurrentPlan}
              sx={{ color: 'white' }}
              onClick={cancelSubscription}
            >
              {isCurrentPlan ? 'Current Plan' : 'Subscribe'}
            </Button>
          )
        }
      </CardActions>
    </Card>
  )
};

export default SubscriptionCard