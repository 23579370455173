import React, { useState } from 'react'
import { 
  Button, 
  Grid, 
  IconButton, 
  ListItem, 
  Tooltip, 
  Typography 
} from '@mui/material';
import { 
  DocumentListGrid, 
  FileListGrid, 
  InnerGridBackgroundContainer 
} from './styled';
import FolderListItem from '../../components/FolderListItem';
import DocumentListItem from '../../components/DocumentListItem';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useAuth0 } from '@auth0/auth0-react';
import DocDetailView from './DocDetailView';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@emotion/react';
import FolderControlsMobile from '../../components/FolderControlsMobile';
import FolderCollaboratorsDialog from '../../components/FolderControls/FolderCollaboratorsDialog';
import AddFolderButton from './AddFolderButton';
import { useDocManagerState } from '../../components/useDocManagerState';

function FilesViewMobile() {

  const theme = useTheme();

  const { 
    isAuthenticated, 
    loginWithRedirect 
  } = useAuth0();

  const {
    ownedMessageFolders,
    sharedMessageFolders,
    folderCollaborators,
    filteredDocs,
    managerView,
    selectedFolder,
    setSelectedFileData,
    setManagerView,
    setSelectedFolder,
  } = useDocManagerState();

  const [folderCollaboratorsOpen, setFolderCollaboratorsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const truncateText = (text) => {
    if(text){
      const maxLength = 8;
      if (text?.length <= maxLength) {
        return text;
      }
      return text.slice(0, maxLength) + '...';
    } else {
      return 'All';
    }
  };

  const handleManagerView = () => {
    switch(managerView){
      case 'Folders':
        return (
          <FileListGrid container sx={{paddingRight: '1rem'}}>
            <InnerGridBackgroundContainer>
              {
                !isAuthenticated && (
                  <ListItem sx={{display: 'flex', justifyContent: 'center'}}>
                    <Button 
                      variant='contained' 
                      color='primary'
                      onClick={() => loginWithRedirect()}
                    >
                      <Typography sx={{color: 'white'}}>
                        <b>Login</b> To Keep Saved Files
                      </Typography>
                    </Button>
                  </ListItem>
                )
              }
              <AddFolderButton />
              <FolderListItem
                folderName={'All'}
                folderId={0}
              />
              {
                ownedMessageFolders?.map((folder) => (
                  <FolderListItem 
                    key={folder?._id}
                    folderName={folder?.name}
                    folderId={folder?._id}
                    folderData={folder}
                    shared={false}
                  />
                ))
              }
              {
                sharedMessageFolders?.map((folder) => (
                  <FolderListItem 
                    key={folder?._id}
                    folderName={folder?.name}
                    folderId={folder?._id}
                    folderData={folder}
                    shared={true}
                  />
                ))
              }
            </InnerGridBackgroundContainer>
          </FileListGrid>
        )
      case 'Files':
        return (
          <DocumentListGrid container>
            <InnerGridBackgroundContainer>
              <Grid 
                sx={{
                  display: 'flex', 
                  alignItems: 'center', 
                  flex: '1', 
                  width: '100%', 
                  justifyContent: 'space-between',
                  maxHeight: '5rem'
                }}
              >
                <IconButton 
                  sx={{
                    marginTop: '0.5rem', 
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    backgroundColor: 'lightgray'
                  }}
                  onClick={() => setManagerView('Folders')}
                >
                  <ArrowBackOutlinedIcon  
                    sx={{
                      color: 'white', 
                      fontSize: '2rem',
                    }} 
                  />
                </IconButton>
                <Grid
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Tooltip title={selectedFolder?.name}>
                    <Typography
                      variant='h4' 
                      sx={{
                        color: 'gray',
                      }}
                    >
                      {truncateText(selectedFolder?.name)}
                    </Typography>
                  </Tooltip>
                  <IconButton
                    sx={{
                      margin: '1rem',
                      border: `1px solid ${theme.palette.primary.lt}`
                    }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <MoreVertIcon sx={{ color: 'gray' }} />
                  </IconButton>
                </Grid>
                <FolderControlsMobile 
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  // handleEditFolderName={handleEditFolderName}
                  setCollaboratorsOpen={setFolderCollaboratorsOpen}
                  selectedFolder={selectedFolder}
                />
                <FolderCollaboratorsDialog
                  open={folderCollaboratorsOpen}
                  collaborators={folderCollaborators}
                  setCollaboratorsOpen={setFolderCollaboratorsOpen}
                  selectedFolderData={selectedFolder}
                />
              </Grid>
              {
                filteredDocs?.map((message) => (
                  <DocumentListItem 
                    messageData={message} 
                    setSelectedFileData={setSelectedFileData}
                    setManagerView={setManagerView}
                    isMobile={true}
                  />
                ))
              }
            </InnerGridBackgroundContainer>
          </DocumentListGrid>
        )
      case 'Document':
        return (
          <DocDetailView />
        )
      default: return (
        <FileListGrid container>
          <InnerGridBackgroundContainer>
            <FolderListItem
              folderName={'All'}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
            />
            {
              ownedMessageFolders?.map((folder) => (
                <FolderListItem 
                  folderName={'All'}
                  folderId={0}
                  selectedFolder={folder}
                  setSelectedFolder={setSelectedFolder} 
                  setManagerView={setManagerView} 
                />
              ))
            }
          </InnerGridBackgroundContainer>
        </FileListGrid>
      )
    }
  }

  return (
    <>
      {
        handleManagerView()
      }
    </>
  )
}

export default FilesViewMobile;