import { Outlet } from 'react-router-dom';
import { AppProvider } from './context';
import MultiRouteWrapper from './Wrapper';
import DemoDialog from '../../components/DemoDialog';

function LiveDemo() {

  return (
    <AppProvider>
      <DemoDialog />
      <MultiRouteWrapper />
      <Outlet />
    </AppProvider>
  );
};

export default LiveDemo;