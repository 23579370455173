import { Typography } from "@mui/material";

const tourSteps = (isMobile) => [
  {
    target: "#ai-provider-demo",
    content: (
      <>
        <Typography variant='h6'>Select A.I. Provider</Typography>
        <Typography>
          At any point during your conversation with the A.I. you can select a different A.I. provider from this dropdown that best fits your immediate needs.
        </Typography>
      </>
    ),
    data: {
      next: 'ai-chat/save',
    },
    placement: 'auto',
    disableBeacon: true
  },
  {
    target: "#user-message-demo",
    content: (
      <>
        <Typography variant='h6'>Save Response</Typography>
        <Typography>
          The top of every A.I. response has a save button. If you click on it you can save the response as a custom document in a custom folder for editing and sharing.
        </Typography>
      </>
    ),
    data: {
      previous: 'ai-chat',
      next: 'folders',
    },
    placement: 'auto',
    disableBeacon: true
  },
  {
    target: '#demo-folder-list',
    content: (
      <>
        <Typography variant='h6'>
          Folders
        </Typography>
        <Typography>
          In the folder manager you can see all of your custom folders and all folders shared with you as well as create new custom folders.
        </Typography>
      </>
    ),
    data: {
      previous: 'ai-chat/save',
      next: 'folders/1',
    },
    placement: isMobile ? 'bottom-start' : 'auto',
    disableOverlay: false
  },
  {
    target: '#demo-folder-controls',
    content: (
      <>
        <Typography variant='h6'>
          Folder Controls
        </Typography>
        {
          isMobile ? (
            <Typography>
              Inside your custom folder clicking on the '3 dot' icon button on mobile there's options to delete your owned folders or share the whole folder with someone else via email. 
            </Typography>
          ) : (
            <Typography>
              Your owned folders have options to edit the folder name, move documents to a different folder, delete the folder, or share the whole folder with someone else via email.
            </Typography>
          )
        }
      </>
    ),
    data: {
      previous: 'folders',
      next: 'folders/messageList/1',
    },
    placement: isMobile ? 'bottom-start' : 'auto',
    disableOverlay: false
  },
  {
    target: '#demo-folder-document-list',
    content: (
      <>
        <Typography variant='h6'>
          Documents / Saved Messages
        </Typography>
        <Typography>
          This is an example of what a folder would look like with a list of your saved messages. Clicking on the message takes you to it with more options available.
        </Typography>
      </>
    ),
    data: {
      previous: 'folders/1',
      next: 'folders/messages/1',
    },
    placement: isMobile ? 'bottom-start' : 'auto',
    disableOverlay: false
  },
  {
    target: isMobile ? '#mobile-demo-document-header' : '#demo-document-header',
    content: (
      <>
        <Typography variant='h6'>
          Message Options
        </Typography>
        {
          isMobile ? (
            <Typography>
              Clicking the "Options" button at the top right of the screen shows options for collaborators, editing, resume conversation, and collaborative chat.
            </Typography>
          ) : (
            <Typography>
              Saved messages have the name, collaborator options, editing, resume conversation, and collaborative chat all at the top of each document.
            </Typography>
          )
        }
      </>
    ),
    data: {
      previous: 'folders/messageList/1',
      next: 'dashboard/5',
    },
    placement: isMobile ? 'bottom-start' : 'auto',
    disableOverlay: false
  },
  {
    target: '#saved-messsages-dash',
    content: (
      <>
        <Typography variant='h6'>Dashboard - Saved A.I. Responses</Typography>
        <Typography>
          All of your recently saved A.I. responses show up here as a list for quick access.
        </Typography>
      </>
    ),
    data: {
      next: 'dashboard/6',
      previous: 'folders/messages/1'
    },
    disableOverlay: false
  },
  {
    target: '#shared-folders-documents-dash',
    content: (
      <>
        <Typography variant='h6'>Dashboard - Shared Items</Typography>
        <Typography>
          Here we have easy access to folders and saved messages that have recently been shared with you.
        </Typography>
      </>
    ),
    data: {
      previous: 'dashboard/5',
      next: 'dashboard/7'
    },
    disableOverlay: false
  },
  {
    target: '#saved-folders-dash',
    content: (
      <>
        <Typography variant='h6'>Dashboard - Recent Folders</Typography>
        <Typography>
          Here you can see a list of your most recently created folders.
        </Typography>
      </>
    ),
    data: {
      next: 'dashboard/7',
      previous: 'dashboard/6'
    },
    disableOverlay: false
  },
];

export default tourSteps;