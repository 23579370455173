import { 
  Routes, 
  Route, 
  Navigate,
  Outlet, 
} from 'react-router-dom';
import { AppContainer } from './styled';
import Profile from './registeredViews/Profile';
import { useDispatch, useSelector } from 'react-redux';
import AppNavigation from './components/AppNavigation';
import { CircularProgress, CssBaseline, Grid, useMediaQuery } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Support from './registeredViews/Support';
import Admin from './registeredViews/Admin';
import Folders from './registeredViews/Folders';
import ChatHistoryList from './registeredViews/ChatHistoryList';
import InviteScreen from './unregisteredViews/InviteScreen';
import FAQPage from './unregisteredViews/FAQ';
import Dashboard from './registeredViews/Dashboard';
import AIChat from './registeredViews/AppHome';
import LiveDemo from './unregisteredViews/LiveDemo';
import AIChatDemo from './unregisteredViews/AIChatDemo';
import FoldersDemo from './unregisteredViews/FoldersDemo';
import DashboardDemo from './unregisteredViews/DashboardDemo';
import FolderListDemo from './unregisteredViews/FolderListDemo';
import { useAuth0 } from '@auth0/auth0-react';
import { saveUserInfo } from './actions/userActions';
import DocumentDemo from './unregisteredViews/DocumentDemo';
import Subscriptions from './registeredViews/Subscriptions';

function App() {

	const drawerWidth = 240;
	const domain = "dev-q4q12mtcytgjyi8s.us.auth0.com";
  
	const { 
		user, 
		getAccessTokenSilently,
    isLoading
	} = useAuth0();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [authCheckCompleted, setAuthCheckCompleted] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(isMobile ? false : true);

  const userInfo = useSelector((state) => state?.userData?.loginInfo);

  console.log(
    '%c Go away, nothing to see here ;)', 
    'background: #66a18c; color: white; font-size: 20px;'
  );

  const PrivateRoutes = () => {
    if (!authCheckCompleted) {
      // Show a loading indicator while authentication check is ongoing
      return (
        <Grid
          sx={{
            height: '100dvh',
            width: '90dvw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress
            sx={{
              height: '5rem',
              width: '5rem',
              color: '#84A8FF'
            }}
          />
        </Grid>
      );
    };
  
    if (user) {
      return <Outlet />;
    } else {
      return <Navigate to="/demo/ai-chat" />;
    };
  };

  useMemo(() => {
		
		if(user){
			const getUserMetadata = async () => {
								
				try {
					const accessToken = await getAccessTokenSilently({
						authorizationParams: {
							audience: `https://${domain}/api/v2/`,
							scope: "read:current_user",
						},
					});
		
					const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user?.sub}`;
					const metadataResponse = await fetch(userDetailsByIdUrl, {
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					});
		
					const user_metadata = await metadataResponse.json();
					
					if(user_metadata){
						dispatch(saveUserInfo(user_metadata, dispatch));
					};
					
		
				} catch (e) {
					console.error('Error: ', e.message);
				}
			};

			getUserMetadata();

		};

	// eslint-disable-next-line
	}, [user]);

  useEffect(() => {
    if (!isLoading) { 
      if (userInfo?.user_id || !user) {
        setAuthCheckCompleted(true);
      } else {
        setAuthCheckCompleted(false);
      };
    };
  }, [user, userInfo, isLoading]);
  
  return (
    <>
      <AppContainer>
			  <CssBaseline />
        <AppNavigation 
          setDrawerOpen={setDrawerOpen} 
          drawerOpen={drawerOpen} 
          drawerWidth={drawerWidth}
        />
        <Routes>

          <Route   path = "/demo"                         element = { <LiveDemo      drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  }  >
            <Route path = "ai-chat"                       element = { <AIChatDemo    drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />
            <Route path = "ai-chat/save"                  element = { <AIChatDemo    drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />
            <Route path = "dashboard/:id"                 element = { <DashboardDemo drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />
            <Route path = "folders"                       element = { <FoldersDemo   drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />
            <Route path = "folders/:folderId"             element = { <FoldersDemo   drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />
            <Route path = "folders/messageList/:folderId" element = { <FolderListDemo drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />
            <Route path = "folders/messages/:messageId"   element = { <DocumentDemo   drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />
            <Route path = "support"                       element = { <FoldersDemo   drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />
          </Route>

          <Route path = "/invite"                             element = { <InviteScreen  drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />
          <Route path = "/faq"                                element = { <FAQPage       drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />

          <Route element  =  { <PrivateRoutes /> }>
            <Route path = "/" element={<Navigate to="/dashboard" />} />

            <Route path = "/support"                      element = { <Support         drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />
            <Route path = "/profile/:slug"                element = { <Profile         drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />
            <Route path = "/ai-chat"                      element = { <AIChat          drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />            
            <Route path = "/dashboard"                    element = { <Dashboard       drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />            
            <Route path = "/admin"                        element = { <Admin           drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />            
            <Route path = "/folders"                      element = { <Folders         drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />            
            <Route path = "/folders/:folderId"            element = { <Folders         drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />            
            <Route path = "/folders/messages/:messageId"  element = { <Folders         drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />            
            <Route path = "/chat-history"                 element = { <ChatHistoryList drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />            
            <Route path = "/subscriptions"                element = { <Subscriptions   drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />            
          </Route>        
        </Routes>
      </AppContainer>
    </>
  );
};

export default App;
