import React from 'react';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined';
import { Menu, MenuItem } from '@mui/material';
import { useDocManagerState } from '../../components/useDocManagerState';
import DocManagerHandlers from '../../containers/DocumentManager/DocManagerHandlers';

function DemoFolderControlsMobile({
  anchorEl,
  setAnchorEl
}) {

  const { setCollaboratorsOpen } = useDocManagerState();
  const { handleDeleteFolder } = DocManagerHandlers();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={false}
      onClose={handleMenuClose}
    >
      <MenuItem 
        onClick={() => { 
          setCollaboratorsOpen(true);
          handleMenuClose(); 
        }}
      >
        <GroupOutlinedIcon sx={{ marginRight: '1rem' }} /> Collaborators
      </MenuItem>
        <MenuItem
          onClick={(e) => { 
            handleDeleteFolder();
            handleMenuClose(); 
          }}
        >
          <DeleteForeverOutlined sx={{ color: 'red', marginRight: '1rem'}} /> Delete Folder
        </MenuItem>
    </Menu>
  )
}

export default DemoFolderControlsMobile;