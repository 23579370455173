import React from 'react'
import ContentArea from '../../components/ContentArea';
import FoldersContainer from '../FoldersDemo/FoldersContainer';
import { DrawerHeader, Main } from '../../styled';

function FolderListDemo({ drawerOpen, drawerWidth }) {

  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<FoldersContainer />} />
    </Main>
  );
}

export default FolderListDemo;