import React, { useEffect } from 'react';
import { 
  ParentGrid 
} from './styled';
import { useSelector } from 'react-redux';
import FilesViewDesktop from './FilesViewDesktop';
import FilesViewMobile from './FilesViewMobile';
import { 
  CircularProgress, 
  Grid, 
  Typography, 
  useMediaQuery 
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDocManagerState } from '../../components/useDocManagerState';

function DocumentManager() {

  const params = useParams();

  const ownedMessages = useSelector((state) => state.gptChat.ownedDbMessages);
  const externalMessages = useSelector((state) => state.gptChat.externalDbMessages);
  const dbMessagesLoading = useSelector((state) => state?.gptChat?.dbMessagesLoading);

  const {
    ownedMessageFolders,
    sharedMessageFolders,
    selectedFolder,
    setManagerView,
    setSelectedFileData,
    setSelectedFolder
  } = useDocManagerState();

	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    
    if(params?.folderId){
      const findFolderById = [...ownedMessageFolders, ...sharedMessageFolders]?.find(
        (folder => folder?._id === params?.folderId)
      );

      setSelectedFolder(findFolderById);
      return;
    };
  
    if (params?.messageId) {
      const _id = params.messageId;
      const updatedFileData = [
        ...(ownedMessages ?? []), 
        ...(externalMessages ?? [])
      ].find((msg) => msg._id === _id);
  
      setSelectedFileData(updatedFileData || null);
      setManagerView('Document');

      const updatedFolder = ownedMessageFolders?.find(
        (folder) => folder?._id === updatedFileData?.folderId
      );

      if(updatedFolder){
        setSelectedFolder(updatedFolder);
      };

    } else {
      const updatedFolder = ownedMessageFolders?.find(
        (folder) => selectedFolder?._id === folder?._id
      );

      if(updatedFolder){
        setSelectedFolder(updatedFolder);
      };

      setManagerView('Folders');
    };
    //eslint-disable-next-line
  }, [
    ownedMessages, 
    externalMessages, 
    ownedMessageFolders, 
    sharedMessageFolders,
    params
  ]);

  return (
    <ParentGrid container>
      {
        dbMessagesLoading ? (
          <Grid 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              flexDirection: 'column',
              alignItems: 'center', 
              width: '-webkit-fill-available', 
              height: '-webkit-fill-available'
            }}
          >
            <Typography variant='h5' sx={{color: 'white', marginBottom: '1rem'}}>
              Loading Messages...
            </Typography>
            <CircularProgress sx={{width: '3rem', height: '3rem', color: '#84A8FF'}}/>
          </Grid>
        ) : (
          
          isMobile ? (
            <FilesViewMobile />
          ) : (
            <FilesViewDesktop />
          )
        )
      }
    </ParentGrid>
  )
}

export default DocumentManager;