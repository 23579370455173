import React, { useState } from 'react'
import { 
  DocumentListGrid, 
  FileListGrid, 
  InnerGridBackgroundContainer 
} from './styled';
import FolderListItem from '../../components/FolderListItem';
import DocumentListItem from '../../components/DocumentListItem';
import FolderControls from '../../components/FolderControls';
import DocDetailView from '../../containers/DocumentManager/DocDetailView';
import AddFolderButton from '../../containers/DocumentManager/AddFolderButton';
import { useDocManagerState } from '../../components/useDocManagerState';
import { ownedMessageFolders, sharedMessageFolders } from './constants.js';

function DemoFilesDesktop({ filteredDocs }) {

  const {
    selectedFolder,
    setSelectedFolder,
    managerView,
    setSelectedFileData,
    setManagerView
  } = useDocManagerState();

  const [docsToMove, setDocsToMove] = useState([]);
  const [folderCollaborators, setFolderCollaborators] = useState([]);

  const ownedFolderIds = ['1'];

  return (
    <>
      {
        managerView === 'Folders' || managerView === 'Files' ? (
          <>
            <FileListGrid container id={'demo-folder-list'}>
              <InnerGridBackgroundContainer>
                <AddFolderButton />
                <FolderListItem
                  folderName={'All'}
                  folderId={0}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder} 
                  setManagerView={setManagerView}
                />
                {
                  ownedMessageFolders?.map((folder) => (
                    <FolderListItem 
                      key={folder?._id}
                      folderName={folder?.name}
                      folderId={folder?._id}
                      folderData={folder}
                      shared={false}
                    />
                  ))
                }
                {
                  sharedMessageFolders?.map((folder) => (
                    <FolderListItem 
                      key={folder?._id}
                      folderName={folder?.name}
                      folderId={folder?._id}
                      folderData={folder}
                      shared={true}
                    />
                  ))
                }
              </InnerGridBackgroundContainer>
            </FileListGrid>
            <DocumentListGrid container >
              <InnerGridBackgroundContainer sx={{ justifyContent: 'flex-start' }} >
                {
                  selectedFolder && (
                    <FolderControls 
                      folderCollaborators={folderCollaborators}
                      setFolderCollaborators={setFolderCollaborators}
                      selectedFolder={selectedFolder}
                      ownedFolderIds={ownedFolderIds}
                      setSelectedFolder={setSelectedFolder}
                      demoActive={true}
                    />
                  )
                }
                <div id={'demo-folder-document-list'}>
                  {
                    filteredDocs?.map((message, index) => (
                      <DocumentListItem 
                        key={index}
                        messageData={message} 
                        setSelectedFileData={setSelectedFileData}
                        setManagerView={setManagerView}
                        setDocsToMove={setDocsToMove}
                        docsToMove={docsToMove}
                      />
                    ))
                  }
                </div>
              </InnerGridBackgroundContainer>
            </DocumentListGrid>
          </>
        ) : (
          <DocDetailView />
        )
      }
    </>
  )
}

export default DemoFilesDesktop;