import React, { useState } from 'react'
import { 
  Grid, 
  IconButton, 
  Tooltip, 
  Typography 
} from '@mui/material';
import { 
  DocumentListGrid, 
  FileListGrid, 
  InnerGridBackgroundContainer 
} from './styled';
import FolderListItem from '../../components/FolderListItem';
import DocumentListItem from '../../components/DocumentListItem';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@emotion/react';
import AddFolderButton from '../../containers/DocumentManager/AddFolderButton';
import DemoFolderControlsMobile from './DemoFolderControlsMobile';
import { useDocManagerState } from '../../components/useDocManagerState';
import { useAppContext } from '../LiveDemo/context';
import { ownedMessageFolders, sharedMessageFolders} from './constants.js';

function DemoFilesMobile({ filteredDocs }) {

  const theme = useTheme();

  const {
    selectedFolder,
    setSelectedFolder,
    setSelectedFileData,
    setManagerView
  } = useDocManagerState();

  const { state: { stepIndex } } = useAppContext();

  const [anchorEl, setAnchorEl] = useState(null);

  const truncateText = (text) => {
    if(text){
      const maxLength = 8;
      if (text?.length <= maxLength) {
        return text;
      }
      return text.slice(0, maxLength) + '...';
    } else {
      return 'All';
    }
  };

  const handleManagerView = () => {
    switch(stepIndex){
      case 2:
        return (
          <FileListGrid 
            container 
            sx={{paddingRight: '1rem'}}
          >
            <InnerGridBackgroundContainer>
              <div id={'demo-folder-list'}>
                <AddFolderButton />
                <FolderListItem
                  folderName={'All'}
                  folderId={0}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder} 
                  setManagerView={setManagerView}
                />
                {
                  ownedMessageFolders?.map((folder) => (
                    <FolderListItem 
                      key={folder?._id}
                      folderName={folder?.name}
                      folderId={folder?._id}
                      folderData={folder}
                      shared={false}
                    />
                  ))
                }
                {
                  sharedMessageFolders?.map((folder) => (
                    <FolderListItem 
                      key={folder?._id}
                      folderName={folder?.name}
                      folderId={folder?._id}
                      folderData={folder}
                      shared={true}
                    />
                  ))
                }
              </div>
            </InnerGridBackgroundContainer>
          </FileListGrid>
        )
      case 3:
        return (
          <DocumentListGrid container>
            <InnerGridBackgroundContainer>
              <Grid 
                sx={{
                  display: 'flex', 
                  alignItems: 'center', 
                  flex: '1', 
                  width: '100%', 
                  justifyContent: 'space-between',
                  maxHeight: '5rem'
                }}
              >
                <IconButton 
                  sx={{
                    marginTop: '0.5rem', 
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    backgroundColor: 'lightgray'
                  }}
                  onClick={() => setManagerView('Folders')}
                >
                  <ArrowBackOutlinedIcon  
                    sx={{
                      color: 'white', 
                      fontSize: '2rem',
                    }} 
                  />
                </IconButton>
                <Grid
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Tooltip title={selectedFolder?.name}>
                    <Typography
                      variant='h4' 
                      sx={{
                        color: 'gray',
                      }}
                    >
                      {truncateText(selectedFolder?.name)}
                    </Typography>
                  </Tooltip>
                  <IconButton
                    id={'demo-folder-controls'}
                    sx={{
                      margin: '1rem',
                      border: `1px solid ${theme.palette.primary.lt}`
                    }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <MoreVertIcon sx={{ color: 'gray' }} />
                  </IconButton>
                </Grid>
                <DemoFolderControlsMobile 
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                />
              </Grid>
              <div id={'demo-folder-document-list'}>
                {
                  filteredDocs?.map((message) => (
                    <DocumentListItem 
                      messageData={message} 
                      setSelectedFileData={setSelectedFileData}
                      setManagerView={setManagerView}
                      isMobile={true}
                    />
                  ))
                }
              </div>
            </InnerGridBackgroundContainer>
          </DocumentListGrid>
        )
      case 4:
        return (
          <DocumentListGrid container>
            <InnerGridBackgroundContainer>
              <Grid 
                sx={{
                  display: 'flex', 
                  alignItems: 'center', 
                  flex: '1', 
                  width: '100%', 
                  justifyContent: 'space-between',
                  maxHeight: '5rem'
                }}
              >
                <IconButton 
                  sx={{
                    marginTop: '0.5rem', 
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    backgroundColor: 'lightgray'
                  }}
                  onClick={() => setManagerView('Folders')}
                >
                  <ArrowBackOutlinedIcon  
                    sx={{
                      color: 'white', 
                      fontSize: '2rem',
                    }} 
                  />
                </IconButton>
                <Grid
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Tooltip title={selectedFolder?.name}>
                    <Typography
                      variant='h4' 
                      sx={{
                        color: 'gray',
                      }}
                    >
                      {truncateText(selectedFolder?.name)}
                    </Typography>
                  </Tooltip>
                  <IconButton
                    sx={{
                      margin: '1rem',
                      border: `1px solid ${theme.palette.primary.lt}`
                    }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <MoreVertIcon sx={{ color: 'gray' }} />
                  </IconButton>
                </Grid>
                <DemoFolderControlsMobile 
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                />
              </Grid>
              <div id={'demo-folder-document-list'}>
                {
                  filteredDocs?.map((message) => (
                    <DocumentListItem 
                      messageData={message} 
                      setSelectedFileData={setSelectedFileData}
                      setManagerView={setManagerView}
                      isMobile={true}
                    />
                  ))
                }
              </div>
            </InnerGridBackgroundContainer>
          </DocumentListGrid>
        )
      default: return (
        <FileListGrid container>
          <InnerGridBackgroundContainer>
            <FolderListItem
              folderName={'All'}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
            />
            {
              ownedMessageFolders?.map((folder) => (
                <FolderListItem 
                  folderName={'All'}
                  folderId={0}
                  selectedFolder={folder}
                  setSelectedFolder={setSelectedFolder} 
                  setManagerView={setManagerView} 
                />
              ))
            }
          </InnerGridBackgroundContainer>
        </FileListGrid>
      )
    }
  }

  return (
    <>
      {
        handleManagerView()
      }
    </>
  )
}

export default DemoFilesMobile;